<template>
    <div class="tw-mb-7">
        <AppCarousel
            :title="title"
            identifier="business"
            :items="businesses"
            :separator="seperator"
            :gradient="gradient"
            :fade="fade"
            :items-per-view="itemsPerView"
            :arrows="arrows"
            :above-arrows="false"
            :infinite="infinite"
            :centered="centered"
            :space-between="spaceBetween"
            :full-width="false"
            :mobile-width="mobileWidth"
            client:visible
        >
            <template #belowTitle>
                <slot />
            </template>
            <template #default="{ item }">
                <SingleBusiness
                    :business="item"
                />
            </template>
        </AppCarousel>
    </div>
</template>

<script>
import AppCarousel from "@app/molecules/AppCarousel.vue";
import SingleBusiness from "@home/atoms/SingleBusiness.vue";

export default {
    components: {SingleBusiness, AppCarousel},
    props: {
        businesses: {
            type: Array,
            required: true
        },
        seperator: {
            type: Boolean,
            required: false,
            default: true
        },
        gradient: {
            type: Object,
            required: false,
            default: () => ({mobile: false, tablet: false, desktop: false})
        },
        fade: {
            type: Boolean,
            required: false,
            default: false
        },
        mobileWidth: {
            type: String,
            required: false,
            default: ''
        },
        itemsPerView: {
            type: Object,
            required: false,
            default: () => ({mobile: 2, tablet: 2, desktop: 5})
        },
        centered: {
            type: Object,
            required: false,
            default: () => ({mobile: false, tablet: false, desktop: false})
        },
        arrows: {
            type: Object,
            required: false,
            default: () => ({mobile: true, tablet: true, desktop: true})
        },
        spaceBetween: {
            type: Object,
            required: false,
            default: () => ({mobile: '10', tablet: '10', desktop: '10'})
        },
        infinite: {
            type: Boolean,
            required: false,
            default: false
        },
        title: {
            type: String,
            required: false,
            default: ''
        }
    }
};
</script>
