<template>
    <div class="tw-h-auto tw-p-1">
        <div class="tw-h-full">
            <div
                class="tw-justify-center tw-px-[12px] tw-pb-[8px] tw-flex tw-w-full tw-h-full tw-items-center"
            >
                <AppImage
                    ref="image"
                    :src="business.image.src"
                    :alt="business.image.alt"
                    classes="tw-block tw-w-32 tw-h-20 tw-object-contain"
                    loading="lazy"
                    :width="128"
                    :height="80"
                />
            </div>
        </div>
    </div>
</template>

<script>

import AppImage from "@app/atoms/AppImage.vue";

export default {
    components: {AppImage},
    props: {
        business: {
            type: Object,
            required: true
        }
    }
};
</script>
